export enum EManageAction {
  MAIN_ACTION = 'MAIN_ACTION',
  CREATE_GROUP = 'CREATE_GROUP',
  ADD_WIDGET = 'ADD_WIDGET',
  SETUP = 'SETUP',
}

export enum EPlatformEvent {
  UNINSTALL_APP = 'UNINSTALL_APP',
  OPEN_DASHBOARD = 'OPEN_DASHBOARD',
}
