import {
  IntegrationApplication,
  removeApplications,
} from '@wix/members-area-integration-kit';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { InitialAppData, PageData } from '@wix/editor-platform-sdk-types';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import EditorWrapper from './EditorWrapper';
import {
  GROUP_PAGE_ID,
  GROUP_PAGES,
  GROUPS_DASHBOARD_URL,
  GROUPS_MEMBERS_AREA_PAGE_ID,
  GROUPS_PAGE_ID,
  GROUPS_WIDGET_ID,
  PAGE_TITLES,
  PAID_PLANS_APP_DEF_ID,
} from '../constants';

import { GroupsAppSpec } from './types/AppSpec';
import { TPARef } from './types/common';
import { EManageAction } from './constants';
import { LayoutType } from 'settings/consts';

export default class GroupPlatform {
  private readonly editor: EditorWrapper;

  constructor(
    protected editorSDK: FlowEditorSDK,
    public readonly appDefId: string,
    private readonly flowAPI: EditorScriptFlowAPI,
    private readonly config: InitialAppData,
  ) {
    this.editor = new EditorWrapper(editorSDK, appDefId);
  }

  async install() {
    await this.setupGroupsPages();
  }

  private async setupGroupsPages() {
    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);
    const { applicationId } = tpaData;

    const groupPages = this.getGroupsTPARefs(allSitePages, applicationId);
    await this.editor.addToManagedPages(groupPages);
    await this.setGroupsPageInitialSettings();
    await this.setPageInitialTitles(groupPages);
    await this.editor.save();
  }

  async setPageInitialTitles(pages: TPARef[]) {
    try {
      await Promise.all(
        pages.map(async (page) => {
          const title = this.flowAPI.translations.t(
            (PAGE_TITLES as any)[page.title as string],
          );
          if (title) {
            return this.editor.updatePage(page.pageRef, {
              title,
            });
          } else {
            this.flowAPI.errorMonitor.captureMessage(
              `Page title is not provided for ${page.title}`,
            );
          }
        }),
      );
    } catch (e: any) {
      this.flowAPI.errorMonitor.captureException(e);
    }
  }

  async setGroupPageState(applicationId: string) {
    try {
      const groupPage = await this.findGroupPage(applicationId);
      if (groupPage?.id) {
        await this.editor.setPageState({
          groupPage: [{ id: groupPage.id, type: 'DESKTOP' }],
        });
      }
    } catch (e) {
      console.error('Set group page state: FAIL', e);
    }
  }

  private async findGroupPage(applicationId: string) {
    const allSitePages = await this.editor.getAllPages();
    const groupPage = allSitePages.find((page) => {
      return (
        page.managingAppDefId === applicationId &&
        page.tpaPageId === GROUP_PAGE_ID
      );
    });
    return groupPage;
  }

  async navigateToGroupPage() {
    try {
      const groupPage = await this.findGroupPage(this.appDefId);
      if (groupPage?.id) {
        await this.editor.navigate(groupPage.id);
      }
    } catch (e) {
      console.log('[GroupPlatform.navigateToGroupPage] Error', e);
    }
  }

  async setGroupsPageInitialSettings() {
    const groupsTPASectionRef = await this.editor.getComponentRef(
      GROUPS_WIDGET_ID,
    );

    if (groupsTPASectionRef) {
      await this.editor.setPublicData(groupsTPASectionRef, {
        key: 'groupListLayout',
        value: LayoutType.sideBar,
      });
    }
  }

  // For sites where groups members area was installed
  // TODO: 🚨 remove later see https://jira.wixpress.com/browse/GROUP-673
  async deleteGroupsMemberArea() {
    try {
      const allSitePages = await this.editor.getAllPages();
      const groupPage: PageData | undefined = allSitePages.find((page) => {
        return page.tpaPageId === GROUPS_MEMBERS_AREA_PAGE_ID;
      });
      if (groupPage) {
        await removeApplications([
          { pageId: GROUPS_MEMBERS_AREA_PAGE_ID } as IntegrationApplication,
        ]);
      }
    } catch (e) {
      console.error('Remove group member area page: FAIL', e);
    }
  }

  private getGroupsTPARefs(allSitePages: PageData[], applicationId: number) {
    const groupPages: TPARef[] = allSitePages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          page?.tpaPageId &&
          GROUP_PAGES.includes(page?.tpaPageId),
      )
      .map((pageData) => {
        return {
          title: pageData.tpaPageId,
          pageRef: { id: pageData.id!, type: 'DESKTOP' },
        };
      });
    return groupPages;
  }

  private async getStaticsEditorBaseUrl() {
    const {
      appFields: {
        platform: { baseUrls },
      },
    } = await this.editor.getAppData<GroupsAppSpec>();
    return baseUrls.staticsEditorBaseUrl;
  }

  addPage() {
    return this.editor.addPage();
  }

  private getGroupsTPARef(allSitePages: any[], applicationId: number) {
    const groupsPage: PageData = allSitePages.find(
      (page) =>
        page.tpaApplicationId === applicationId &&
        page.tpaPageId === GROUPS_PAGE_ID,
    );
    return { title: GROUPS_PAGE_ID, pageRef: { id: groupsPage.id } };
  }

  async deleteApp() {
    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);
    const { applicationId } = tpaData;
    const groupsPage = this.getGroupsTPARef(allSitePages, applicationId);
    if (groupsPage) {
      return this.editor.deletePage(groupsPage);
    }
  }

  handleInstallError() {}

  performAction(actionId: EManageAction) {
    switch (actionId) {
      case EManageAction.MAIN_ACTION:
        return this.openDashboard();
      case EManageAction.CREATE_GROUP:
        return this.openDashboard('/templates');
      case EManageAction.ADD_WIDGET:
        return this.openAddWidget();
      case EManageAction.SETUP:
        return this.openDashboard('/settings');
      default:
        return;
    }
  }

  openDashboard(section: string = '') {
    this.editor.openDashboard(GROUPS_DASHBOARD_URL + section);
  }

  private async openAddWidget() {
    try {
      await this.editorSDK.editor.openModalPanel('', {
        title: this.flowAPI.translations.t('groups-web.settings.addons'),
        url: getPanelUrl('Groups', 'Addons'),
        width: 800,
        height: 165 * 3,
      });
    } catch (e) {
      console.log('[GroupPlatform.openAddWidget] Error', e);
    }
  }

  async changeLandingPage() {
    try {
      const eventType = 'focusedPageChanged';
      const handler = async () => {
        await Promise.all([
          this.navigateToGroupPage(),
          this.editor.removeEventListener(eventType, handler),
        ]);
      };
      await this.editor.addEventListener(eventType, handler);
    } catch (e) {
      console.log('[GroupPlatform.changeLandingPage] Error', e);
    }
  }

  async handleMigration(payload: { withPricingPlans?: boolean }) {
    if (payload?.withPricingPlans) {
      return this.editor.installTPA(PAID_PLANS_APP_DEF_ID);
    }
  }
}
