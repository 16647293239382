import {
  EditorReadyFn,
  OnEventFn,
  HandleActionFn,
} from '@wix/yoshi-flow-editor';
import { maybeInstallMembersArea } from '@wix/members-area-integration-kit';

import {
  EventType,
  InstallationOriginType,
} from '@wix/editor-platform-sdk-types';

import GroupPlatform from './GroupPlatform';

import { EPlatformEvent } from './constants';
import { getAppManifest } from './manifest';

let platform: GroupPlatform;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  options,
  flowAPI,
) => {
  const { firstInstall, initialAppData, origin, monitoring } = options;

  const { errorMonitor } = flowAPI;

  const isSilentInstallation =
    origin.info?.type === InstallationOriginType.SILENT_INSTALL_SITE_CREATION ||
    origin.info?.type === InstallationOriginType.SILENT_INSTALL;

  platform = new GroupPlatform(
    editorSDK,
    appDefinitionId,
    flowAPI,
    initialAppData,
  );

  try {
    if (firstInstall) {
      await platform.install();
      await platform.changeLandingPage();

      if (!isSilentInstallation) {
        await maybeInstallMembersArea({ biData: options.biData });
      }
    } else {
      await platform.setGroupPageState(appDefinitionId);
      // For sites where groups members area was installed
      // TODO: 🚨 remove later see https://jira.wixpress.com/browse/GROUP-673
      await platform.deleteGroupsMemberArea();
    }
  } catch (error) {
    errorMonitor.captureException(error as Error);
    await platform.deleteApp();
    throw error;
  }
};

/**
 * TODO rewrite
 * @deprecated
 */
export const onEvent: OnEventFn = (event, sdk) => {
  switch (event.eventType as EventType | EPlatformEvent) {
    case EPlatformEvent.UNINSTALL_APP:
      return sdk.application.uninstall('', { openConfirmation: true });

    case EPlatformEvent.OPEN_DASHBOARD:
      return platform.openDashboard();

    case EventType.pageDeleted:
      return platform.deleteApp();

    case EventType.appActionClicked:
      return platform.performAction(event.eventPayload.actionId);
    default:
      return;
  }
};

/**
 * TODO rewrite
 * @deprecated
 */
export const handleAction: HandleActionFn = async ({ type, payload }) => {
  if (!platform) {
    // very strange case when editor ready is called after `handleAction`
    return;
  }

  switch (type) {
    case 'appInstalled':
      const { appDefinitionId } = payload as { appDefinitionId: string };

      if (appDefinitionId === platform.appDefId) {
        await platform.setGroupPageState(appDefinitionId);
      }

      break;

    case 'migrate':
      await platform.handleMigration(payload);
      break;

    default:
      break;
  }
};

export { getAppManifest };
